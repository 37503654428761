iframe#webpack-dev-server-client-overlay{display:none!important}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  -ms-overflow-style: none;
  scrollbar-width: 0.3125rem;

  ::-webkit-scrollbar {
    background-color: #f6f8fb;
    width: 0.3125rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #8196b3;
  }
}

body,
textarea,
select,
input {
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "#ECEFF4";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

textarea::placeholder {
  color: #8196b3;
  opacity: 0.7;
}

.modal {
  position: absolute;
  z-index: 1000;
  background-color: white;
  top: 20%;
  left: 10%;
  width: 80%;
  height: 70%;
  border: 1px solid black;
}

.modal2 {
  position: fixed;
  z-index: 10000;
}

* {
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;;
}

@keyframes loading {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.skeleton-loader {
  background: linear-gradient(90deg, #F6F8FB 25%, #fefefe8e 50%, #F6F8FB 75%);
  background-size: 200px 100%;
  animation: loading 1.5s infinite;
}